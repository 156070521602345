@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  min-height: 100%;
  background-color: white !important;
}

#__next {
  height: 100%;
}

code {
  font-family: "Inter", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue";
  padding: 0.1rem 0.4rem;
  font-size: 90%;
  color: #d400ff;
  border-radius: 0.1335rem;
}
code:not([class*="language-"]):before,
code:not([class*="language-"]):after {
  content: "`";
}
code[class*="language-"] {
  padding: 0;
}

/* ---------- CSS For create order page ---------- */
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chronicle+Disp+Cond:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap");
/* ----------------------------------------------- */

/* .css-h8sl5y-MuiSvgIcon-root-MuiSelect-icon {
  color: red !important;
} */

[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="search"]:focus,
[type="checkbox"]:focus,
[type="radio"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: var(--primary-color);
  border-color: var(--primary-color);
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 0px;
}
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background: none;
  color: black;
}
input:focus,
textarea:focus {
  outline: none !important;
  outline-offset: 0 !important;
  --tw-ring-offset-width: none !important;
}

li:focus-visible {
  outline: none;
}

li:hover {
  cursor: pointer;
}

.tab-panel--selected {
  animation-name: tabPanel;
  animation-duration: 300ms;
  animation-timing-function: linear;
}
@keyframes tabPanel {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Input style */
.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-container label {
  position: absolute;
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input-container input {
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.ps-menu-icon {
  width: unset !important;
  min-width: unset !important;
  justify-content: start !important;
  margin: 0 !important;
}
.ps-menu-button,
.ps-menu-icon {
  height: unset !important;
}
.ps-menu-button:hover {
  background-color: unset !important;
}
.ps-sidebar-container,
.ps-submenu-content {
  background-color: transparent !important;
}
.ps-collapsed .ps-menu-icon {
  min-width: 100% !important;
  justify-content: center !important;
}
.ps-collapsed {
  width: 80px !important;
}
.ps-sidebar-container,
.ps-menu-root,
.ps-menu-root > ul {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ps-sidebar-root {
  min-width: unset !important;
  border: none !important;
}
.ps-collapsed,
.ps-collapsed .ps-menu-root,
.ps-collapsed .ps-menu-button {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: unset !important;
  margin-right: unset !important;
}
.ps-collapsed ul {
  align-items: center;
}
.ps-menu-label {
  display: flex;
  justify-content: space-between;
}

/* scroll */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e0ddddea;
  border-radius: 10px;
}

th {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
}
th:not(:first-child) {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
}

th:first-child {
  padding-left: 10px;
  padding-right: 0;
}

td {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
}
td:not(:first-child) {
  padding-top: 10px;
  padding-bottom: 10px;
  /* padding-right: 10px; */
  padding-right: 0;
}

td:first-child {
  padding-left: 10px;
  /* padding-right: 0; */
  padding-right: 10px;
}

.bgGrad {
  background: linear-gradient(90deg, #89746f 0%, #d1ad91 100%);
}
.secondaryDisabledText {
  color: #919eab;
  font-size: 0.875rem;
  line-height: 1.5714285714285714;
  margin: 0;
  font-weight: 400;
}
